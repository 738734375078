@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --ff-primary: "InterDisplay", sans-serif;
  --fs-body: 1rem;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", sans-serif;
  }
}

body {
  margin: 0;
  margin: 0;
  font-family: var(--ff-primary), sans-serif;
  font-size: var(--fs-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
