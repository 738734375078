@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay"),
    url("../fonts/InterDisplay-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay"),
    url("../fonts/InterDisplay-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Light"),
    url("../fonts/InterDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay"),
    url("../fonts/InterDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Medium"),
    url("../fonts/InterDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-SemiBold"),
    url("../fonts/InterDisplay-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Bold"),
    url("../fonts/InterDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-ExtraBold"),
    url("../fonts/InterDisplay-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Black"),
    url("../fonts/InterDisplay-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay"),
    url("../fonts/InterDisplay-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay"),
    url("../fonts/InterDisplay-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Light"),
    url("../fonts/InterDisplay-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay"),
    url("../fonts/InterDisplay-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Medium"),
    url("../fonts/InterDisplay-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-SemiBold"),
    url("../fonts/InterDisplay-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Bold"),
    url("../fonts/InterDisplay-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-ExtraBold"),
    url("../fonts/InterDisplay-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "InterDisplay";
  src:
    local("InterDisplay-Black"),
    url("../fonts/InterDisplay-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
